import axios from "axios";
import { useTranslation } from "react-i18next";
import { useReadLocalStorage } from "usehooks-ts";
import useAnalytics from "~/hooks/useAnalytics";
import Button from "~/ui/Button/Button";

interface DocumentItemProps {
    item: SourceDocument;
}
export interface SourceDocument {
    SourceDocumentID: number;
    CategoryID: number;
    CategoryName: string;
    CategoryTitle: string;
    CategoryDescription: string;
    CategoryTags: string;
    ThumbnailImage: string;
    PdfFilePath: string;
    DocGuidID: string;
}

const DocumentItem = ({ item }: DocumentItemProps) => {
    const token = useReadLocalStorage("_t") as string;
    const { t } = useTranslation();
    const { sp_click } = useAnalytics();

    const downloadDocument = (item: SourceDocument) => {
        sp_click("N", "download", "Market Intelligence Document download", `${item.CategoryTitle}`, {
            _title: "Explore Market Intelligence results",
        });

        return axios
            .get(`${import.meta.env.VITE_API_HOST}/api/download/document/id?documentID=${item.SourceDocumentID}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;

                link.setAttribute("download", item.CategoryTitle || "pdf");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    const previewDocument = (item: SourceDocument) => {
        return axios
            .get(`${import.meta.env.VITE_API_HOST}/api/download/document/id?documentID=${item.SourceDocumentID}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: "blob",
            })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data], { type: response.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("target", "_blank");
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    return (
        <div className="rounded-md shadow-sm p-4 xl:p-6 bg-white w-full h-full flex-grow" key={item.DocGuidID}>
            <div className="flex flex-grow h-full items-center text-left ">
                <img
                    src={`${import.meta.env.VITE_API_HOST}${item.ThumbnailImage}`}
                    alt=""
                    className="w-[248px] max-h-[320px]"
                />

                <div className="flex-1 px-8 py-4 h-full flex flex-col justify-between">
                    <div>
                        <h2 className="leading-[120%]">{item.CategoryTitle}</h2>

                        {item.CategoryTags && (
                            // <div className="flex py-6">
                            //     <Button className="h-[46px] text-sm focus-visible:outline-none px-5 rounded-full text-foreground-light bg-white shadow-sm">
                            //         {item.CategoryTags}
                            //     </Button>
                            // </div>
                            <h4>{item.CategoryTags}</h4>
                        )}
                    </div>

                    <p className="text-sm text-foreground-light mt-4">{item.CategoryDescription}</p>

                    <div className="flex mt-4">
                        <Button color="primary" icon="download" className="w-44" onPress={() => downloadDocument(item)}>
                            {t("download")}
                        </Button>
                        <Button color="light" icon="image" className="w-44 ml-4" onPress={() => previewDocument(item)}>
                            {t("preview")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentItem;
