import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { motion } from "framer-motion";
import i18next from "i18next";
import React, { useState } from "react";
import { TextField, Input, Label } from "react-aria-components";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { z } from "zod";
import { UserCredentialsSchema } from "~/schemas/AccountSchema";
import Button from "~/ui/Button/Button";

const ResetPasswordSchema = UserCredentialsSchema.pick({
    newPassword: true,
    confirmPassword: true,
}).refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
});

type ResetPasswordType = z.infer<typeof ResetPasswordSchema>;

const ResetPassword: React.FunctionComponent = () => {
    i18next.loadNamespaces("fields");

    const { t } = useTranslation();
    const [isSuccess, setIsSuccess] = useState(false);
    const [searchParams] = useSearchParams();

    // console.log(searchParams.get("id"));

    const {
        register,
        handleSubmit,
        // setError,
        formState: { errors, isSubmitting },
    } = useForm<ResetPasswordType>({
        resolver: zodResolver(ResetPasswordSchema),
        defaultValues: {
            newPassword: "",
            confirmPassword: "",
        },
        mode: "all",
    });

    const onSubmit = handleSubmit(async (data: ResetPasswordType) => {
        await axios
            .post(`${import.meta.env.VITE_API_HOST}/api/reset-password`, {
                ...data,
                username: searchParams.get("id"),
                passwordRequest: searchParams.get("guid"),
            })
            .then(() => {
                setIsSuccess(true);
            })
            .catch((error) => {
                // const { status, data } = error.response;
                console.log(error);
                setIsSuccess(false);
            })
            .finally(() => {
                // setIsProcessing(false);
            });
    });

    const renderResetPasswordForm = () => {
        return (
            <motion.div initial={{ opacity: 0, x: 48 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -48 }}>
                <Link to="/auth/login" className="absolute top-2 right-2">
                    <Button icon="close" size="md" className="!bg-transparent" />
                </Link>
                <div className="text-center">
                    <h1 className="text-gradient">{t("resetPassword.title")}</h1>
                    <form className="w-[346px] mx-auto" onSubmit={onSubmit}>
                        <div className="py-4">
                            <TextField
                                className={`text-field full-width mb-4 ${
                                    errors.newPassword ? "text-field--error" : ""
                                }`}
                            >
                                <Input
                                    type="password"
                                    placeholder={`${t("fields:password")}`}
                                    {...register("newPassword")}
                                />
                                <Label>
                                    {errors.newPassword ? errors.newPassword.message : t("fields:newPassword")}
                                </Label>
                            </TextField>
                            <TextField
                                className={`text-field full-width mb-4 ${
                                    errors.confirmPassword ? "text-field--error" : ""
                                }`}
                            >
                                <Input
                                    type="password"
                                    placeholder={`${t("fields:confirmPassword")}`}
                                    {...register("confirmPassword")}
                                />
                                <Label>
                                    {errors.confirmPassword
                                        ? errors.confirmPassword.message
                                        : t("fields:confirmPassword")}
                                </Label>
                            </TextField>
                        </div>
                        <Button
                            type="submit"
                            color="gradient"
                            size="lg"
                            fullWidth
                            className="mb-10"
                            isDisabled={isSubmitting}
                        >
                            {t("submit")}
                        </Button>
                    </form>
                </div>
            </motion.div>
        );
    };

    const renderSuccessScreen = () => {
        return (
            <motion.div initial={{ opacity: 0, x: 48 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -48 }}>
                <div className="text-center">
                    <h1 className="text-gradient">{t("resetPassword.newPasswordCreated")}</h1>
                    <p className="text-base mt-6 mb-10 whitespace-pre-line">{t("resetPassword.successMessage")}</p>
                    <Link to="/auth/login" className="w-full flex justify-center">
                        <Button color="gradient" size="md">
                            {t("continue")}
                        </Button>
                    </Link>
                </div>
            </motion.div>
        );
    };

    return isSuccess ? renderSuccessScreen() : renderResetPasswordForm();
};

export default ResetPassword;
