import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import { AnalyticsContext } from "~/providers/AnalyticsProvider";
import { LanguageContext } from "~/providers/LanguageProvider";

import Button from "~/ui/Button/Button";
import DocumentModal from "./DocumentModal";
import PrivacyPolicy from "~/features/LegalDocuments/US/PrivacyPolicyEN";
import PrivacyPolicyEN from "~/features/LegalDocuments/FR/PrivacyPolicyEN";
import PrivacyPolicyFR from "~/features/LegalDocuments/FR/PrivacyPolicyFR";

const UserConsent = () => {
    const CK_CONSENT = "consent_track";
    const REGION = import.meta.env.VITE_REGION;
    const [consentGiven, setConsentGiven] = useState(document.cookie.includes(CK_CONSENT));
    const [showModal, setShowModal] = useState(false);

    const { t } = useTranslation();
    const { language } = useContext(LanguageContext);
    const { set_consent } = useContext(AnalyticsContext);

    const policy = REGION === "us" ? PrivacyPolicy : language === "en" ? PrivacyPolicyEN : PrivacyPolicyFR;

    const handleConsent = (consent: boolean) => {
        // Set the tracking consent in cookies
        document.cookie = `${CK_CONSENT}=${consent}; path=/`;
        setConsentGiven(true);
        set_consent(consent);
    };

    return (
        <>
            <Transition
                show={!consentGiven}
                as="div"
                className="w-full absolute bottom-0 z-30"
                leave="ease-in duration-100"
                leaveFrom="bottom-0"
                leaveTo="-bottom-10"
            >
                <div className="w-full flex justify-between items-center bg-white border-t-[1px] border-gray py-4 px-16">
                    <div className="leading-normal">
                        {t("consent.msg")}{" "}
                        <button className="text-primary" onClick={() => setShowModal(true)}>
                            {t("legalDocs.privacyPolicy")}
                        </button>
                        .
                    </div>
                    <div className="flex gap-2 pl-8">
                        <Button color="primary" onPress={() => handleConsent(true)}>
                            {t("consent.accept")}
                        </Button>
                        <Button onPress={() => handleConsent(false)}>{t("consent.decline")}</Button>
                    </div>
                </div>
            </Transition>

            {!consentGiven && showModal && (
                <DocumentModal
                    title={t("legalDocs.privacyPolicy")}
                    content={policy}
                    closeFn={() => setShowModal(false)}
                />
            )}
        </>
    );
};

export default UserConsent;
