import * as React from "react";
import i18next from "i18next";
import { Button } from "react-aria-components";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useReadLocalStorage } from "usehooks-ts";
import { fetcher } from "~/hooks/useFetcher";
import NoResult from "../Search/NoResult";
import DocumentItem, { SourceDocument } from "./DocumentItem";

const CategoryItems: React.FunctionComponent = () => {
    const token = useReadLocalStorage("_t") as string;
    const { language } = i18next;

    const { categoryId } = useParams();

    const { data, isLoading, mutate } = useSWR(
        `/api/market-intelligence/search?marketIntelligenceCategoryId=${categoryId}`,
        (url) => fetcher(url, token)
    );

    React.useEffect(() => {
        mutate();
    }, [language]);

    const renderSkeleton = () => (
        <>
            {[...Array(10)].map((_, index) => (
                <div
                    className="rounded-md shadow-sm p-4 bg-white w-full flex"
                    key={`reader-intelligence-skeleton-${index}`}
                >
                    <div className="flex items-center text-left flex-grow">
                        <div className="w-[248px] h-full bg-gray-200 animate-pulse rounded-2xl"></div>
                        <div className="py-[75px] px-[94px] w-full">
                            <h3 className="text-[23px] text-foreground-dark h-6 rounded-full bg-gray-200 animate-pulse w-1/2"></h3>
                            <div className="flex py-6 gap-2">
                                <Button className="h-[46px] text-sm focus-visible:outline-none px-5 rounded-full text-foreground-light bg-gray-200 animate-pulse w-40 shadow-sm "></Button>
                                <Button className="h-[46px] text-sm focus-visible:outline-none px-5 rounded-full text-foreground-light bg-gray-200 animate-pulse w-40 shadow-sm "></Button>
                                <Button className="h-[46px] text-sm focus-visible:outline-none px-5 rounded-full text-foreground-light bg-gray-200 animate-pulse w-40 shadow-sm "></Button>
                            </div>

                            <p className="text-sm text-foreground-light mt-4 w-1/3 h-5 bg-gray-200 rounded-full animate-pulse"></p>
                            <p className="text-sm text-foreground-light mt-4 w-2/3 h-5 bg-gray-200 rounded-full animate-pulse"></p>
                            <div className="btn bg-gray-200 animate-pulse h-[54px] w-44 mt-4"></div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );

    return (
        data && (
            <div className="w-screen h-screen relative z-8 overflow-hidden flex flex-col">
                <h1 className="pt-[124px] mb-2 text-white text-center">{data.DocumentTypeName}</h1>
                <div className="grid grid-cols-1 2xl:grid-cols-2 flex-grow overflow-auto place-items-center gap-4 p-4 pt-0 xl:gap-6 xl:pb-6 xl:px-12">
                    {isLoading ? (
                        renderSkeleton()
                    ) : data.MarketIntelligence.length === 0 ? (
                        <NoResult />
                    ) : (
                        data.MarketIntelligence.map((item: SourceDocument, index: number) => (
                            <DocumentItem key={index} item={item} />
                        ))
                    )}
                </div>
            </div>
        )
    );
};

export default CategoryItems;
