import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App.tsx";
import "./i18n";
import LanguageProvider from "./providers/LanguageProvider.tsx";
import AdvancedSearchProvider from "./providers/AdvancedSearchProvider.tsx";
import AnalyticsProvider from "./providers/AnalyticsProvider.tsx";

import * as Sentry from "@sentry/react";
import Config from "./config/appConfig.ts";

if (Config.SENTRY.enabled) {
    console.log(import.meta.env.MODE);
    Sentry.init({
        dsn: Config.SENTRY.dsn,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", Config.API_HOST],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        // Set app environment
        environment: import.meta.env.MODE,

        // Performance Monitoring
        tracesSampleRate: Config.SENTRY.trace_rate, //  Capture 100% of the transactions

        // Session Replay
        replaysSessionSampleRate: Config.SENTRY.session_rate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: Config.SENTRY.error_rate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <BrowserRouter>
            <LanguageProvider>
                <AnalyticsProvider>
                    <AdvancedSearchProvider>
                        <App />
                    </AdvancedSearchProvider>
                </AnalyticsProvider>
            </LanguageProvider>
        </BrowserRouter>
    </React.StrictMode>
);
