const envData = import.meta.env;

export default {
    API_HOST: envData.VITE_API_HOST,

    SENTRY: {
        enabled: envData.VITE_SENTRY_ENABLED === "true" || false,
        dsn: envData.VITE_SENTRY_DSN || "",
        trace_rate: envData.VITE_SENTRY_TRACES_SAMPLE_RATE,
        session_rate: envData.VITE_SENTRY_SESSION_REPLAY_RATE,
        error_rate: envData.VITE_SENTRY_ERROR_REPLAY_RATE,
    },
};
