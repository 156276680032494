import * as React from "react";
import "./styles/index.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Gradient2 from "~/assets/gradient_2.png";

// import { changeLanguage } from "i18next";
import { useReadLocalStorage } from "usehooks-ts";
import useAnalytics from "./hooks/useAnalytics";

import AuthLayout from "./layouts/AuthLayout";
import RegistrationSuccess from "./features/Account/Registration/RegistrationSuccess";
import ResetPassword from "./features/Account/ForgotPassword/ResetPassword";

import SearchPage from "./pages/Search";
import SearchResult from "./features/Search/SearchResult";

import MarketIntelligencePage from "./pages/MarketIntelligence";
import Categories from "./features/MarketIntelligence/Categories";
import CategoryItems from "./features/MarketIntelligence/CategoryItems";
import UserConsent from "./components/UserConsent";

const Login = React.lazy(() => import("./features/Account/Login"));
const Registration = React.lazy(() => import("./features/Account/Registration/Registration"));
const ForgotPassword = React.lazy(() => import("./features/Account/ForgotPassword/ForgotPassword"));

function App() {
    const navigate = useNavigate();
    const token = useReadLocalStorage("_t");
    const location = useLocation();
    const { sp_track_contact } = useAnalytics();

    React.useEffect(() => {
        sp_track_contact({});

        if (token) {
            navigate("/search");
        } else if (location.pathname === "/") {
            navigate("/auth/login");
        }

        // if (import.meta.env.VITE_REGION === "us") {
        //   changeLanguage("us");
        // }
    }, []);

    return (
        <>
            {/* <AppHeader /> */}
            <React.Suspense fallback={<></>}>
                <img className="fixed inset-0 w-screen h-screen object-cover page-bg" src={Gradient2} alt="" />
                <Routes>
                    <Route path="/search" element={<SearchPage />}></Route>
                    <Route path="/search/result" element={<SearchResult />}></Route>
                    <Route path="/auth" element={<AuthLayout />}>
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<Registration />} />
                        <Route path="register/success" element={<RegistrationSuccess />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                    </Route>

                    <Route path="/market-intelligence" element={<MarketIntelligencePage />}>
                        <Route index element={<Categories />} />
                        <Route path=":categoryId" element={<CategoryItems />} />
                    </Route>
                    {/* <Route path="/reader-intelligence" element={<MarketIntelligencePage />}>
                        <Route index element={<Categories />} />
                        <Route path=":categoryId" element={<CategoryItems />} />
                    </Route> */}
                </Routes>
                <UserConsent />
            </React.Suspense>
        </>
    );
}

export default App;
