import * as React from "react";
import LogoLight from "./../assets/logo_light.png";
import { Outlet } from "react-router-dom";

const AuthLayout: React.FunctionComponent = () => {
  return (
    <div className="flex z-10 relative w-screen h-screen mr-[523px]">
      <div className="flex-grow flex items-center justify-center">
        <img src={LogoLight} className="h-[102px]" alt="" />
      </div>
      <section className="max-w-[700px] min-w-[500px] w-2/4 overflow-hidden m-2 bg-[#F7F3F2] shadow-2xl rounded-xl relative flex items-center justify-center">
        <Outlet />
      </section>
    </div>
  );
};

export default AuthLayout;
