import * as React from "react";
import { Link } from "react-router-dom";

const RegistrationSuccess: React.FunctionComponent = () => {
    return (
        <div className="w-[346px] text-center">
            <p className="text-base mb-4">
                Thank you for requesting more information. We have received your request. A Labrador company
                representative will be in touch shortly.
            </p>
            <Link to="/auth/login" className="text-primary underline underline-offset-[3px]">
                Go to login
            </Link>
        </div>
    );
};

export default RegistrationSuccess;
